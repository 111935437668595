import { getPosition } from "../../utils/utils";

/**
 * Private
 */
let mainElement = false;
let currentIndex = 0;
let prevIndex = false;
let clicks = [];
let loc = false;

/**
 * Enable menu
 */
const enable = (locScroll) => {
	mainElement = document.querySelector(".js-HowWeWork");
	if (!mainElement) return false;
	loc = locScroll;
	reset();
};

const reset = () => {
	//clicks
	clicks = mainElement.querySelectorAll(".js-HowWeWork-click");
	clicks.forEach((element) => element.addEventListener("click", makeClick));
};

const destroy = () => {
	clicks.forEach((element) => element.removeEventListener("click", makeClick));

	mainElement = false;
	currentIndex = 0;
	prevIndex = false;
	clicks = [];
	loc = false;
};

const makeClick = (e) => {
	const eventIndex = e.currentTarget.dataset.index;
	if (!eventIndex) return false;
	setIndex(eventIndex);

	const go = getPosition(mainElement, true);

	loc.scrollTo(go);
};

const setIndex = (index) => {
	if (index - 1 === currentIndex) return false;
	prevIndex = currentIndex;
	currentIndex = index - 1;

	const itemsNav = mainElement.querySelectorAll(".js-HowWeWork-nav li");
	const items = mainElement.querySelectorAll(
		".js-HowWeWork-items .HowWeWork__item"
	);

	items[currentIndex].classList.add("is-active");
	itemsNav[currentIndex].classList.add("is-active");

	items[prevIndex].classList.remove("is-active");
	itemsNav[prevIndex].classList.remove("is-active");
};

/**
 * HowWeWork
 * @param  {Object} settings
 * @return {Object} public API
 */
const HowWeWork = () => {
	return {
		enable,
		reset,
		destroy,
		setIndex,
	};
};

export default HowWeWork;
