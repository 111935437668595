/**
 *
 * Library of reusable functions
 * --------------------------------
 *
 * Useable by importing them from _utils
 *
 */

// --- Functions ---
export { diff, clamp, map } from "./functions/math";
export { delay, getPosition } from "./functions/functions";
export { pageTransition, contentAnimation } from "./anim";
export { default as TextBalancer } from "./TextBalancer";
