/**
 * delay
 */

export function delay(n) {
	n = n || 2000;
	return new Promise((done) => {
		setTimeout(() => {
			done();
		}, n);
	});
}

export function getPosition(elem, top) {
	var distance = elem.getBoundingClientRect();

	return (
		Math.abs(
			document.querySelector("[data-scroll-container]").getBoundingClientRect()
				.top
		) + (top ? distance.top + 1 : distance.bottom)
	);
}
