/**
 * vendor
 */

import barba from "@barba/core";
import LocomotiveScroll from "locomotive-scroll";

/**
 * Fonts
 */
import FontFaceObserver from "fontfaceobserver";
var font = new FontFaceObserver("Flexa Compressed");

/**
 * Components and etc
 */
import { Menu, Video, HowWeWork } from "./components/components";
import { delay, pageTransition, TextBalancer } from "./utils/utils";

let scroll;
let textBa;
let videoJS = Video();
let work = HowWeWork();

const player = new Plyr(".player-inline");

barba.init({
	transitions: [
		{
			name: "custom-transition",
			sync: true,

			once({ next }) {
				// init LocomotiveScroll on page load
				smooth(next.container);
				firstLoad();
			},
			async leave(data) {
				scroll.destroy();
				const done = this.async();
				pageTransition();
				await delay(750);
				done();

				videoJS.closeVideo();
				window.scrollTo(0, 0);
				// create your stunning leave animation here
			},
			async enter({ next }) {
				scroll.destroy();
				work.destroy();
			},
			async after({ next }) {
				textBa.reset();
				videoJS.reset();
				// init LocomotiveScroll regarding the next page
				smooth(next.container);
			},
		},
	],
});

function firstLoad() {
	Menu();

	font.load().then(function () {
		scroll.update();
		textBa = TextBalancer();
		// textBa.reset();
	});

	document.querySelector("html").classList.add("is-loaded", "is-ready");
	document.querySelector("html").classList.remove("is-loading");
}

function smooth(container) {
	scroll = new LocomotiveScroll({
		el: container.querySelector("[data-scroll-container]"),
		smooth: true,
	});

	if (container.classList.contains("is-news")) {
		document.querySelector("body").classList.add("is-news");
	} else {
		document.querySelector("body").classList.remove("is-news");
	}

	work.enable(scroll);
}
