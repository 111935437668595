import anime from "animejs/lib/anime.es.js";

/**
 * page transition
 */

export function pageTransition() {
	anime.set(".Loading__inner", {
		width: "0%",
		left: "0%",
	});

	// Create a timeline with default parameters
	var tl = anime.timeline({
		easing: "easeOutExpo",
		duration: 1000,
	});

	// Add children
	tl.add({
		targets: ".Loading__inner",
		width: "100%",
		left: "0%",
	}).add({
		targets: ".Loading__inner",
		width: "100%",
		left: "100%",
	});

	anime({
		targets: ".Loading__back",
		opacity: 1,
		duration: 500,
		endDelay: 250,
		easing: "linear",
		direction: "alternate",
	});
}

/**
 * content animation
 */

export function contentAnimation() {
	// anime.set(".Loading__inner", {
	// 	width: "0%",
	// 	left: "0%",
	// });
	// // Create a timeline with default parameters
	// var tl = anime.timeline({
	// 	easing: "easeOutExpo",
	// 	duration: 1000,
	// });
	// // Add children
	// tl.add({
	// 	targets: ".Loading__inner",
	// 	width: "100%",
	// 	left: "0%",
	// }).add({
	// 	targets: ".Loading__inner",
	// 	width: "100%",
	// 	left: "100%",
	// });
}
