/**
 * Private
 */
let video = false;
let closeEls = [];
let openEls = [];
let player = false;

const videoOptions = {
	controls: ["play", "progress", "current-time", "mute", "volume"],
	ratio: "16:9",
};

/**
 * Enable video
 */
const enable = () => {
	video = document.querySelector(".js-video");
	player = new Plyr(".js-video-player", videoOptions);

	reset();
};

const reset = () => {
	openEls.forEach((element) => element.removeEventListener("click", openVideo));
	openEls = document.querySelectorAll(".js-video-open");
	openEls.forEach((element) => element.addEventListener("click", openVideo));

	closeEls.forEach((element) =>
		element.removeEventListener("click", closeVideo)
	);
	closeEls = document.querySelectorAll(".js-video-close");
	closeEls.forEach((element) => element.addEventListener("click", closeVideo));
};

const openVideo = (e) => {
	e.preventDefault();

	const videoUrl = e.currentTarget.dataset.videoUrl;
	const posterUrl = e.currentTarget.dataset.poster;
	const videoUrlMobile = e.currentTarget.dataset.videoUrlMobile;
	const type = e.currentTarget.dataset.type;

	if (e.currentTarget.href) {
		addContent(e.currentTarget.href);
	}

	if (videoUrl) {
		document.querySelector("body").classList.add("--video-open");
		video.classList.add("is-active");
		video.querySelector(
			".js-video-frame"
		).style.backgroundImage = `url(${posterUrl})`;

		const fileExt = videoUrl.substr(videoUrl.lastIndexOf(".") + 1);
		const filetype = type + "/" + fileExt;

		player.source = {
			type: type,
			poster: posterUrl,
			sources:
				videoUrl && videoUrlMobile
					? [
							{
								src: videoUrlMobile,
								type: filetype,
								size: 854,
							},
							{
								src: videoUrl,
								type: filetype,
								size: 1280,
							},
					  ]
					: [
							{
								src: videoUrl,
								type: filetype,
								size: 1280,
							},
					  ],
		};

		player.restart();
		player.play();
	}
};

const closeVideo = (e) => {
	document.querySelector("body").classList.remove("--video-open");
	video.classList.remove("is-active");
	video.querySelector(".js-video-frame").style.backgroundImage = ``;
	player.pause();
	setTimeout(() => {
		video.querySelector(".js-video-content").innerHTML = "";
		video.scrollTo(0, 0);
	}, 500);
};

const addContent = (href) => {
	if (!href) return false;

	fetch(href)
		.then(function (response) {
			// The API call was successful!
			return response.text();
		})
		.then(function (html) {
			// Convert the HTML string into a document object
			var parser = new DOMParser();
			var doc = parser.parseFromString(html, "text/html");

			// Get the video and content
			var contentSingle = doc.querySelector(".Single__content");
			if (contentSingle) {
				video.querySelector(".js-video-content").appendChild(contentSingle);
			}
		})
		.catch(function (err) {
			// There was an error
			console.warn("Something went wrong.", err);
		});
};

/**
 * Video
 * @param  {Object} settings
 * @return {Object} public API
 */
const Video = () => {
	enable();

	return {
		enable,
		openVideo,
		closeVideo,
		reset,
	};
};

export default Video;
