/**
 * Private
 */
let menuItem = false;
let elements = [];

/**
 * Enable menu
 */
const enable = () => {
	menuItem = document.querySelector(".js-menu");
	elements = document.querySelectorAll(".js-menu a, .js-menu-toggle");
	elements.forEach((element) => element.addEventListener("click", toggle));
};

const toggle = (status) => {
	menuItem.classList.toggle("is-active");
};

/**
 * Menu
 * @param  {Object} settings
 * @return {Object} public API
 */
const Menu = () => {
	enable();

	return {
		enable,
		toggle,
	};
};

export default Menu;
